<template>
  <NuxtLayout name="search-basic-layout">
    <h1 class="text-[32px] text-center">空間便利店 登入</h1>
    <LoginLayout class="max-w-[500px] mt-[30px] p-[20px]" :is-dialog="false"></LoginLayout>
  </NuxtLayout>
</template>
<script setup>
import LoginLayout from "@/components/LoginLayout.vue";
import {provide} from "vue";

definePageMeta(
    { title: '會員登入' }
)

const seoMeta = {
  title: '空間便利店 登入頁面｜方便順利的場地租借平台。時租日租場地，活動聚會場地，商務會議線上預約',
  description: '場地租借要便利，就在空間便利店。一分鐘線上預訂，即時確認，方便又安心。場地租借、活動空間、會議室，各種場地一步搞定',
  ogTitle: '空間便利店 空間文章｜方便順利的場地租借平台。時租日租場地，活動聚會場地，商務會議線上預約',
  ogSiteName: '空間便利店',
  ogDescription: '場地租借要便利，就在空間便利店。一分鐘線上預訂，即時確認，方便又安心。場地租借、活動空間、會議室，各種場地一步搞定',
  ogUrl: 'https://www.onestep.place',
  ogType: 'website',
  ogImage: 'https://pic.onestep.place/images/onestep-og.png',
  ogImageAlt: '空間便利店｜方便順利的場地租借平台。時租日租場地，活動聚會場地，商務會議線上預約'
};

useSeoMeta(seoMeta);

const layoutProps = {
  footer: false
}
provide('searchBasicLayoutProps', layoutProps);

</script>
<style scoped
       lang="scss">
</style>
